import { useEffect, useState } from 'react';
import './GradientBackground.css'

export const GradientBackground = () => {
    const [currentSection, setCurrentSection] = useState('');

    const checkCurrentSection = () => {
        const scrollY = window.scrollY;
        const detectionOffset = 400;
        const specificBackgrounds = [
            {
                sectionId: 'collaborative',
            },
            {
                sectionId: 'innovative',
            },
            {
                sectionId: 'footer',
            },
        ]
        let foundSection = '';
        for (const background of specificBackgrounds) {
            const section = document.getElementById(background.sectionId)
            if (!section) continue;
            if (section.offsetTop - detectionOffset <= scrollY) {
                foundSection = background.sectionId
            }
        }
        setCurrentSection(foundSection);
    };

    useEffect(() => {
        const onScroll = () => {
            window.requestAnimationFrame(checkCurrentSection);
        };
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [currentSection]);

    return (
        <div className={`gradient-background current-${currentSection}`}>
        </div>
    )
}