import { useTranslation } from 'react-i18next'
import './WorkPrograms.css'
import bank from './icons/bank.svg'
import boxes from './icons/boxes.svg'
import check from './icons/check.svg'

export const WorkPrograms = () => {
    const { t } = useTranslation();
    return (
        <div className="work-programs">
            <Control
                cycle={t('all_in_one.feature.work_programs.widget.controls.bank.name')}
                icon={bank}
                riskLevel="low"
                isSuggested={false}>
                {t('all_in_one.feature.work_programs.widget.controls.bank.control')}
            </Control>
            <Control
                cycle={t('all_in_one.feature.work_programs.widget.controls.stock.name')}
                icon={boxes}
                riskLevel="high"
                isSuggested={true}>
                {t('all_in_one.feature.work_programs.widget.controls.stock.control')}
            </Control>
        </div>
    )
}

const Control = ({children, cycle, riskLevel, icon, isSuggested=false}) => {
    const { t } = useTranslation();
    return (
        <article className={`control risk-${riskLevel} ${isSuggested ? "suggested" : ""} `}>
            <div className={`icon-wrapper`}>
                <img src={icon} alt="audit cycle" />
            </div>
            <div className="desc">
                <em>{cycle}</em>
                <p>{children}</p>
                <span className="badge">
                    {t('all_in_one.feature.work_programs.widget.suggested')}
                </span>
            </div>
            <div className="check">
                <img src={check} alt="check" />
            </div>
        </article>
    )
}