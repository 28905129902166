import { AllInOne } from './sections/AllInOne/AllInOne';
import { Collaboration } from './sections/Collaboration/Collaboration';
import { Excel } from './sections/Excel/Excel';
import { Footer } from './sections/Footer/Footer';
import { Future } from './sections/Future/Future';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { HeroSection } from './sections/HeroSection/HeroSection';
import { NavBar } from './sections/NavBar/NavBar';
import { useEffect } from 'react';
import { WhyDreamAudit } from './sections/WhyDreamAudit/WhyDreamAudit';
import ReactGA from 'react-ga4';
import { GradientBackground } from './atoms/GradientBackground/GradientBackground';
import { useTranslation } from 'react-i18next';


const Home = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const trackingId = process.env.REACT_APP_ANALYTICS_TRACKING_ID
    ReactGA.initialize(trackingId);
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/", title: "Landing Page" });
  }, [])

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {t('common.site_title')}
        </title>
      </Helmet>
      <div className="App">
        <GradientBackground />
        <NavBar />
        <HeroSection />
        <WhyDreamAudit />
        <AllInOne />
        <Excel />
        <Collaboration />
        <Future />
        <Footer />
      </div>
    </HelmetProvider>
  );
}

export default Home;
