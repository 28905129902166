import './Excel.css'
import { ContactButton } from '../../atoms/Button/ContactButton'
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dreamOffice from './Frames/dreamoffice.svg';
import dreamOfficeFr from './Frames/dreamoffice-fr.svg';
import excelTable from './Frames/excel-table.svg';
import excelTableFr from './Frames/excel-table-fr.svg';
import excelWindow from './Frames/excel-window.svg';

export const Excel = () => {
    const [mousePosition, setMousePosition] = useState({
        x: 0,
        y: 0
    })

    function handleMouseMove(e) {
        const section = document.getElementById("excel")
        const sectionCenterX = section.offsetWidth / 2
        const sectionCenterY = section.offsetTop + (section.offsetHeight / 2)
        setMousePosition({
            x: e.pageX - sectionCenterX,
            y: e.pageY - sectionCenterY
        })
    }

    const { t, i18n } = useTranslation()
    let excelTableImage = excelTable
    let dreamOfficeImage = dreamOffice
    switch (i18n.language) {
        case 'fr': {
            excelTableImage = excelTableFr
            dreamOfficeImage = dreamOfficeFr
            break
        }
        default: {
            excelTableImage = excelTable
            dreamOfficeImage = dreamOffice
        }
    }

    return (
        <section id="excel" className="excel" onMouseMove={handleMouseMove}>
            <div className="excel-windows" style={{"--x": `${mousePosition.x}`, "--y": `${mousePosition.y}` }}>
                <img
                    src={excelWindow}
                    className="svg-frame excel-window"
                    alt={t('excel.excel_window')}
                    loading="lazy" />
                <img
                    src={excelWindow}
                    className="svg-frame excel-window bis"
                    alt={t('excel.excel_window')}
                    loading="lazy" />
                <img
                    src={excelTableImage}
                    className="svg-frame excel-table"
                    alt={t('excel.excel_table')}
                    loading="lazy" />
                <img
                    src={dreamOfficeImage}
                    className="svg-frame dreamoffice"
                    alt={t('excel.dreamoffice')}
                    loading="lazy" />
            </div>
            <div className="section-description">
                <h5>
                    {t('excel.subtitle')}
                </h5>
                <h2 dangerouslySetInnerHTML={{__html: t('excel.title', {interpolation: {escapeValue: false}})}}></h2>
                <p>
                    {t('excel.description')}
                </p>
                <ContactButton variant="secondary">
                    {t('excel.cta')}
                </ContactButton>
            </div>
        </section>
    )
}