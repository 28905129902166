import { useTranslation } from 'react-i18next'
import { ContactButton } from '../../atoms/Button/ContactButton'
import './AllInOne.css'
import { AccountingData } from './SubSections/AccountingData/AccountingData'
import { RiskAssessment } from './SubSections/RiskAssessment/RiskAssessment'
import { SubSection } from './SubSections/SubSection'
import { Synthesis } from './SubSections/Synthesis/Synthesis'
import { WorkPrograms } from './SubSections/WorkPrograms/WorkPrograms'
import { WorkingPapers } from './SubSections/WorkingPapers/WorkingPapers'

export const AllInOne = () => {
    const { t } = useTranslation();
    return (
        <section id="all-in-one" className="all-in-one">
            <div className="sticky-heading">
                <div className="sticky-heading-content section-description">
                    <h5>
                        {t('all_in_one.subtitle')}
                    </h5>
                    <h2 dangerouslySetInnerHTML={{__html: t('all_in_one.title', {interpolation: {escapeValue: false}})}}></h2>
                    <p>
                        {t('all_in_one.description')}
                    </p>
                    <ContactButton variant="secondary">
                        {t('all_in_one.cta')}
                    </ContactButton>
                </div>
            </div>
            <div className="sub-sections">
                <SubSection title={t('all_in_one.feature.risk_assessment.title')} animation={<RiskAssessment/>}>
                    {t('all_in_one.feature.risk_assessment.description')}
                </SubSection>
                <SubSection title={t('all_in_one.feature.accounting_data.title')} animation={<AccountingData/>}>
                    {t('all_in_one.feature.accounting_data.description')}
                </SubSection>
                <SubSection title={t('all_in_one.feature.work_programs.title')} animation={<WorkPrograms/>}>
                    {t('all_in_one.feature.work_programs.description')}
                </SubSection>
                <SubSection title={t('all_in_one.feature.working_papers.title')} animation={<WorkingPapers/>}>
                    {t('all_in_one.feature.working_papers.description')}
                </SubSection>
                <SubSection title={t('all_in_one.feature.syntheses.title')} animation={<Synthesis/>}>
                    {t('all_in_one.feature.syntheses.description')}
                </SubSection>
            </div>
        </section>
    )
}