import { useTranslation } from 'react-i18next';
import './LanguageSwitcher.css'
import { useNavigate } from "react-router-dom";

const languages = [
    {
        code: "fr",
        name: "FR"
    },
    {
        code: "en",
        name: "EN"
    },
]

export const LanguageSwitcher = () => {
    const navigate = useNavigate()
    const handleChange = (e) => {
        switch (e.target.value) {
            case 'fr': {
                navigate("/fr")
                break
            }
            default: {
                navigate("/")
            }
        }
    }
    const { i18n } = useTranslation()
    const currentLanguage = i18n.language
    return (
        <select onChange={handleChange} defaultValue={currentLanguage}>
            {languages.map((item, i) => (
                <option key={i} value={item.code}>
                    {item.name}
                </option>
            ))}
        </select>
    )
}

export default LanguageSwitcher;