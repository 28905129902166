import { useAnalyticsEventTracker } from '../../useAnalyticsEventTracker';
import './Buttons.css'


export const Button = ({children, variant="primary", size="medium", href="#", eventName=null, ...rest}) => {
    const handleClick = () => {
        if (eventName) {
            gaEventTracker(eventName)
        }
    }
    const gaEventTracker = useAnalyticsEventTracker('custom');
    return (
        <a className={`button button-${variant} size-${size}`} href={href} onClick={handleClick} {...rest}>
            {children}
        </a>
    )
}

export const PrimaryButton = ({children, ...rest}) => (
    <Button variant="primary" {...rest}>
        {children}
    </Button>
)

export const SecondaryButton = ({children, ...rest}) => (
    <Button variant="secondary" {...rest}>
        {children}
    </Button>
)

export const TertiaryButton = ({children, ...rest}) => (
    <Button variant="tertiary" {...rest}>
        {children}
    </Button>
)

export default PrimaryButton;