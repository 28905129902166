import './HeroSection.css'
import { useTranslation } from 'react-i18next'
import dashboardEn from './assets/dashboard-skeleton.svg'
import dashboardFr from './assets/dashboard-skeleton-fr.svg'
import { ContactButton } from '../../atoms/Button/ContactButton'

export const HeroSection = () => {
    // Safari 3.0+ "[object HTMLElementConstructor]" 
    const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
    const playParallax = !isSafari;

    const { t, i18n } = useTranslation();
    let dashboardImage
    switch (i18n.language) {
        case 'fr': {
            dashboardImage = dashboardFr
            break
        }
        default: {
            dashboardImage = dashboardEn
        }
    }
    return (
        <section id="hero-section" className={`hero-section ${playParallax ? 'parallax' : ''}`}>
            <h1 dangerouslySetInnerHTML={{__html: t('hero_section.title', {interpolation: {escapeValue: false}})}}></h1>
            <p>
                {t('hero_section.description')}
            </p>
            <ContactButton size="large" eventName="book_demo_hero_section">
                {t('common.book_demo')}
            </ContactButton>
            <div className="browser">
                <div className="browser-header">
                    <div className="browser-header-left">
                        <div className="browser-header-button close"></div>
                        <div className="browser-header-button reduce"></div>
                        <div className="browser-header-button expand"></div>
                    </div>
                    <div className="browser-header-center">
                        <div className="search-bar">
                            app.dreamaudit.com
                        </div>
                    </div>
                    <div className="browser-header-right"></div>
                </div>
                <img src={dashboardImage} loading="lazy" alt="SAAS App in your browser" />
            </div>
        </section>
    )
}