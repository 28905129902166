
import { useTranslation } from 'react-i18next';
import { scrollTo } from '../../../Utils'
import { TertiaryButton } from '../../../atoms/Button/Buttons'
import './ArgumentFrame.css'

export const ArgumentFrame = ({title, icon, iconAlt, color, href, children}) => {
    const { t } = useTranslation();
    return (
        <article className="argument-frame">
            <div className="argument-frame-header">
                <div className="img-wrapper" style={{backgroundColor: color}}>
                    <img src={icon} alt={iconAlt} loading="lazy" />
                </div>
                <div className="desc">
                    <h3>{title}</h3>
                    <p>{children}</p>
                </div>
            </div>
            <TertiaryButton
                onClick={scrollTo}
                href={href}
                style={{backgroundColor: color}}>
                {t('why_dreamaudit.cta')}
            </TertiaryButton>
        </article>
    )
}