
import './Future.css'
import { ContactButton } from '../../atoms/Button/ContactButton'
import leaf from './assets/leaf.svg'
import sparkles from './assets/sparkles.svg'
import calendarStar from './assets/calendar-star.svg'
import { useTranslation } from 'react-i18next'

export const Future = () => {
    const { t } = useTranslation();
    return (
        <section id="innovative" className="future">
            <div className="section-description">
                <h5>
                    {t('future_ready.subtitle')}
                </h5>
                <h2>
                    {t('future_ready.title')}
                </h2>
                <p>
                    {t('future_ready.description')}
                </p>
                <ContactButton variant="secondary">
                    {t('future_ready.cta')}
                </ContactButton>
            </div>
            <div className="bubbles">
                <BubbleArgument img={leaf} title={t('future_ready.args.csrd.title')}>
                    {t('future_ready.args.csrd.description')}
                </BubbleArgument>
                <BubbleArgument img={sparkles} title={t('future_ready.args.ia.title')}>
                    {t('future_ready.args.ia.description')}
                </BubbleArgument>
                <BubbleArgument img={calendarStar} title={t('future_ready.args.updates.title')}>
                    {t('future_ready.args.updates.description')}
                </BubbleArgument>
            </div>
        </section>
    )
}

const BubbleArgument = ({img, title, children}) => (
    <article className="bubble-argument">
        <div className="bubble">
            <img src={img} alt={title} loading="lazy" />
        </div>
        <div className="desc">
            <h4 className="title">{title}</h4>
            <p>{children}</p>
        </div>
    </article>
)