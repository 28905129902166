import './Collaboration.css'
import { useTranslation } from 'react-i18next';
import dreamMobile from './assets/dream-mobile.svg';
import dreamMobileFr from './assets/dream-mobile-fr.svg';
import overallProgression from './assets/overall-progression.jpg';
import overallProgressionFr from './assets/overall-progression-fr.jpg';
import qrCode from './assets/qrcode.jpg';
import qrCodeFr from './assets/qrcode-fr.jpg';
import reviewNotes from './assets/review-notes.jpg';
import reviewNotesFr from './assets/review-notes-fr.jpg';

export const Collaboration = () => {
    const { t, i18n } = useTranslation();
    let images
    switch (i18n.language) {
        case 'fr': {
            images = {
                overallProgression: overallProgressionFr,
                reviewNotes: reviewNotesFr,
                qrCode: qrCodeFr,
                dreamMobile: dreamMobileFr
            }
            break
        }
        default: {
            images = {
                overallProgression: overallProgression,
                reviewNotes: reviewNotes,
                qrCode: qrCode,
                dreamMobile: dreamMobile
            }
        }
    }
    return (
        <section id="collaborative" className="collaboration">
            <div className="section-description">
                <h5>
                    {t('collaboration.subtitle')}
                </h5>
                <h2 dangerouslySetInnerHTML={{__html: t('collaboration.title', {interpolation: {escapeValue: false}})}}></h2>
            </div>
            <div className="text-section">
                <h3 className="title">
                    {t('collaboration.args.monitoring.title')}
                </h3>
                <p dangerouslySetInnerHTML={{__html: t('collaboration.args.monitoring.description', {interpolation: {escapeValue: false}})}}></p>
            </div>
            <div className="row">
                <ImageDesc img={images.overallProgression} pos="left">
                    {t('collaboration.images.dashboard')}
                </ImageDesc>
                <ImageDesc img={images.reviewNotes} pos="right">
                    {t('collaboration.images.review_notes')}
                </ImageDesc>
            </div>

            <div className="text-section">
                <h3 className="title">
                    {t('collaboration.args.client_communication.title')}
                </h3>
                <p dangerouslySetInnerHTML={{__html: t('collaboration.args.client_communication.description', {interpolation: {escapeValue: false}})}}></p>
            </div>
            <div className="row">
                <ImageDesc img={images.qrCode}>
                    {t('collaboration.images.attachment_qr')}
                </ImageDesc>
                <ImageDesc img={images.dreamMobile} mobile>
                    {t('collaboration.images.mobile_app')}
                </ImageDesc>
            </div>

            <div className="text-section">
                <h3 className="title">
                    {t('collaboration.args.unlimited_users.title')}
                </h3>
                <p>
                    {t('collaboration.args.unlimited_users.description')}
                </p>
            </div>
        </section>
    )
}

const ImageDesc = ({img, children, pos, mobile=false, ...rest}) => (
    <article {...rest} className={`image-desc img-${pos} ${mobile ? 'mobile' : ''}`}>
        <img src={img} alt={children} loading="lazy" />
        <small>
            {children}
        </small>
    </article>
)