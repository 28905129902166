import { initReactI18next } from 'react-i18next';
import { useParams } from 'react-router-dom';
import en from './en_GB.json'
import fr from './fr_FR.json'
import i18next from 'i18next';
import Home from '../Home';


const LanguagePath = () => {

  const { lang } = useParams();

  i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      // the translations
      // (tip move them in a JSON file and import them,
      // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
      resources: {
        en: en,
        fr: fr
      },
      lng: lang, // if you're using a language detector, do not define the lng option
      fallbackLng: "en",

      interpolation: {
        escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      }
  });
  document.documentElement.lang = lang ?? "en"

  return <Home />;
}

export default LanguagePath;
