import { useTranslation } from 'react-i18next'
import './RiskAssessment.css'
import checkCircle from "./icons/check-circle.svg"
import exclamationCircle from "./icons/exclamation-circle.svg"
import fire from "./icons/fire.svg"

export const RiskAssessment = () => {
    const { t } = useTranslation();
    return (
        <div className="risk-assessment">
            <div className="left">
                <strong className="question">
                    {t('all_in_one.feature.risk_assessment.widget.question')}
                </strong>
                <ul className="isa">
                    <li className="badge">
                        {t('all_in_one.feature.risk_assessment.widget.norm_1')}
                    </li>
                    <li className="badge">
                        {t('all_in_one.feature.risk_assessment.widget.norm_2')}
                    </li>
                </ul>
            </div>
            <div className="risk-level">
                <ul>
                    <li className="low">
                        {t('all_in_one.feature.risk_assessment.widget.risk_level.low.label')}
                    </li>
                    <li className="medium">
                        {t('all_in_one.feature.risk_assessment.widget.risk_level.medium.label')}
                    </li>
                    <li className="high">
                        {t('all_in_one.feature.risk_assessment.widget.risk_level.high.label')}
                    </li>
                    <li className="low">
                        {t('all_in_one.feature.risk_assessment.widget.risk_level.low.label')}
                    </li>
                </ul>
                <strong className="risk-label">
                    {t('all_in_one.feature.risk_assessment.widget.risk_level.title')}
                </strong>
            </div>
            <ul className="indicator">
                <li className="low">
                    <span className="circle">
                        <img
                            src={checkCircle}
                            alt={t('all_in_one.feature.risk_assessment.widget.risk_level.low.full_text')}
                        />
                    </span>
                </li>
                <li className="medium">
                    <span className="circle">
                        <img
                            src={exclamationCircle}
                            alt={t('all_in_one.feature.risk_assessment.widget.risk_level.medium.full_text')}
                        />
                    </span>
                </li>
                <li className="high">
                    <span className="circle">
                        <img
                            src={fire}
                            alt={t('all_in_one.feature.risk_assessment.widget.risk_level.high.full_text')}
                        />
                    </span>
                </li>
            </ul>
        </div>
    )
}