import './NavBar.css'
import logo from '../../assets/logo.svg';
import xmark from './assets/xmark.svg';
import menuBars from './assets/menu-bars.svg';
import PrimaryButton, { SecondaryButton, TertiaryButton } from '../../atoms/Button/Buttons';
import { scrollTo } from '../../Utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useExternalLink } from '../../i18n/useExternalLink';

export const NavBar = () => {

    const [isHeaderSticky, setHeaderSticky] = useState(false);
    const [isCTASticky, setCTASticky] = useState(false);
    const headerThreshold = 128;

    const { clientAppUrl, contactUrl, } = useExternalLink()

    useEffect(() => {
        const stickyCTAThreshold = document.getElementById('hero-section').offsetHeight;
        const stickyCTAThresholdMax = document.body.offsetHeight - window.innerHeight - 200;
        const updateScrollDir = () => {
            const scrollY = window.pageYOffset;
            setHeaderSticky(headerThreshold < scrollY);
            setCTASticky(stickyCTAThreshold < scrollY && scrollY < stickyCTAThresholdMax);
        };

        const onScroll = () => {
            window.requestAnimationFrame(updateScrollDir);
        };

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [isHeaderSticky]);

    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const { t } = useTranslation();

    return (
        <>
            <div className={`header-wrapper ${isHeaderSticky ? "sticky" : ""}`}>
                <header>
                    <nav className="header-left">
                        <a onClick={scrollTo} className="logo" href="hero-section">
                            <img src={logo} alt="DreamAudit Logo" />
                        </a>
                    </nav>
                    <nav>
                        <a className="nav-button" onClick={scrollTo} href="why-dreamaudit">
                            {t("navigation.why_dreamaudit")}
                        </a>
                        <a className="nav-button" onClick={scrollTo} href="all-in-one">
                            {t("navigation.all_in_one")}
                        </a>
                        <a className="nav-button" onClick={scrollTo} href="collaborative">
                            {t("navigation.collaborative")}
                        </a>
                        <a className="nav-button" onClick={scrollTo} href="innovative">
                            {t("navigation.innovative")}
                        </a>
                    </nav>
                    <nav className="header-right">
                        <SecondaryButton href={clientAppUrl} eventName="log_in">
                            {t("navigation.log_in")}
                        </SecondaryButton>
                        { isCTASticky ? <PrimaryButton href={contactUrl} eventName="book_demo_header" target="_blank">
                            {t("common.book_demo")}
                        </PrimaryButton>
                        : ""}
                        <button className="toggle-button" onClick={() => setShowMobileMenu(true)}>
                            <img src={menuBars} alt="menu" />
                        </button>
                    </nav>
                </header>
            </div>
            <aside className={`mobile-menu ${showMobileMenu ? 'show' : ''}`}>
                <div className="overlay" onMouseDown={() => setShowMobileMenu(false)}></div>
                <div className="mobile-menu-content">
                    <nav className="mobile-menu-header">
                        <a onClick={scrollTo} href="hero-section">
                            <img src={logo} alt={t("common.logo")} />
                        </a>
                        <button className="toggle-button" onClick={() => setShowMobileMenu(false)}>
                            <img src={xmark} alt="close button" />
                        </button>
                    </nav>
                    <nav className="nav-buttons">
                        <TertiaryButton size="large" onClick={(e) => {scrollTo(e); setShowMobileMenu(false)}} href="why-dreamaudit">
                            {t("navigation.why_dreamaudit")}
                        </TertiaryButton>
                        <TertiaryButton size="large" onClick={(e) => {scrollTo(e); setShowMobileMenu(false)}} href="all-in-one">
                            {t("navigation.all_in_one")}
                        </TertiaryButton>
                        <TertiaryButton size="large" onClick={(e) => {scrollTo(e); setShowMobileMenu(false)}} href="collaborative">
                            {t("navigation.collaborative")}
                        </TertiaryButton>
                        <TertiaryButton size="large" onClick={(e) => {scrollTo(e); setShowMobileMenu(false)}} href="innovative">
                            {t("navigation.innovative")}
                        </TertiaryButton>
                    </nav>
                    <nav>
                        <PrimaryButton size="large" href={contactUrl} target="_blank">
                            {t("common.book_demo")}
                        </PrimaryButton>
                    </nav>
                </div>
            </aside>
            <footer className={`sticky-cta ${isCTASticky ? 'show' : ''}`}>
                <PrimaryButton href={contactUrl} target="_blank">
                    {t("common.book_demo")}
                </PrimaryButton>
            </footer>
        </>
    )
}