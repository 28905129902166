import { useTranslation } from 'react-i18next';
import './WorkingPapers.css'

export const WorkingPapers = () => {
    const { t } = useTranslation();
    return (
        <div className="working-papers">
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th className="account" style={{width: "50%"}}>
                            {t('all_in_one.feature.working_papers.widget.header.account')}
                        </th>
                        <th className="debit">
                            {t('all_in_one.feature.working_papers.widget.header.debit')}
                        </th>
                        <th className="credit">
                            {t('all_in_one.feature.working_papers.widget.header.credit')}
                        </th>
                    </tr>
                    <tr>
                        <th>A</th>
                        <td className="account">
                            {t('all_in_one.feature.working_papers.widget.row_1.company_name')}
                        </td>
                        <td className="debit">0.00</td>
                        <td className="credit">16.20</td>
                    </tr>
                    <tr>
                        <th>B</th>
                        <td className="account">
                            {t('all_in_one.feature.working_papers.widget.row_2.company_name')}</td>
                        <td className="debit">13.37</td>
                        <td className="credit">0.00</td>
                    </tr>
                </thead>
            </table>
        </div>

    )
}