import './AccountingData.css'
import stonks from "./icons/arrow-stonks.svg"
import { useTranslation } from 'react-i18next';

export const AccountingData = () => {
    const { t } = useTranslation();

    return (
        <div className="accounting-data">
            <div>
                <strong className="account-name">
                    {t('all_in_one.feature.accounting_data.widget.turnover')}
                </strong>
                <span className="amount">
                    <span className="currency">{t('common.currency')}</span>
                    <span className="value">199.5</span>
                    <span className="unit">K</span>
                </span>
            </div>
            <div className="right">
                <span className="badge">
                    <img src={stonks} alt="dollar sign" />
                    <span className="value">28%</span>
                </span>
                <div className="histogram">
                    {
                        [...Array(12)].map((e, i) => <div className={`bar-${i}`} key={i}></div>)
                    }
                    {
                        [...Array(12)].map((e, i) => <div className={`bar-${i}`} key={12+i}></div>)
                    }
                </div>
            </div>
        </div>
    )
}