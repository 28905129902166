import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

export const useExternalLink = () => {
    const [clientAppUrl, setClientAppUrl] = useState(process.env.REACT_APP_CLIENT_APP_URL)
    const [contactUrl, setContactUrl] = useState(process.env.REACT_APP_CONTACT_URL)
    const [contactEmail, setContactEmail] = useState(process.env.REACT_APP_CONTACT_EMAIL)
    const [aboutUsUrl, setAboutUsUrl] = useState(process.env.REACT_APP_ABOUT_US_URL)
    const youtubeUrl = process.env.REACT_APP_YOUTUBE_URL
    const linkedinUrl = process.env.REACT_APP_LINKEDIN_URL

    const { i18n } = useTranslation()
    useEffect(() => {
        switch (i18n.language) {
            case "fr": {
                setClientAppUrl(process.env.REACT_APP_CLIENT_APP_URL_FR)
                setContactUrl(process.env.REACT_APP_CONTACT_URL_FR)
                setContactEmail(process.env.REACT_APP_CONTACT_EMAIL_FR)
                setAboutUsUrl(process.env.REACT_APP_ABOUT_US_URL_FR)
                break
            }
            default: {
                setClientAppUrl(process.env.REACT_APP_CLIENT_APP_URL)
                setContactUrl(process.env.REACT_APP_CONTACT_URL)
                setContactEmail(process.env.REACT_APP_CONTACT_EMAIL)
                setAboutUsUrl(process.env.REACT_APP_ABOUT_US_URL)
            }
        }
    }, [i18n.language])

    return {
        aboutUsUrl,
        clientAppUrl,
        contactEmail,
        contactUrl,
        linkedinUrl,
        youtubeUrl,
    }
}