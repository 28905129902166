import './WhyDreamAudit.css'
import streamIcon from './Icon/Stream.svg'
import puzzleIcon from './Icon/Puzzle.svg'
import rocketIcon from './Icon/Rocket.svg'
import { ArgumentFrame } from './ArgumentFrame/ArgumentFrame'
import { useTranslation } from 'react-i18next'

export const WhyDreamAudit = () => {
    const { t } = useTranslation();
    return (
        <section id="why-dreamaudit" className="why-dreamaudit">
            <h5>
                {t('why_dreamaudit.subtitle')}
            </h5>
            <h2 dangerouslySetInnerHTML={{__html: t('why_dreamaudit.title', {interpolation: {escapeValue: false}})}}></h2>
            <div className="row">
                <ArgumentFrame 
                    title={t('why_dreamaudit.argument.streamlined.title')}
                    icon={streamIcon}
                    iconAlt="audit progression"
                    color="#F7D9E1"
                    href="all-in-one">
                    {t('why_dreamaudit.argument.streamlined.description')}
                </ArgumentFrame>
                <ArgumentFrame
                    title={t('why_dreamaudit.argument.collaborative.title')}
                    icon={puzzleIcon}
                    iconAlt="collaborative audit"
                    color="#D9E7F4"
                    href="collaborative">
                    {t('why_dreamaudit.argument.collaborative.description')}
                </ArgumentFrame>
                <ArgumentFrame
                    title={t('why_dreamaudit.argument.future_ready.title')}
                    icon={rocketIcon}
                    iconAlt="innovative future"
                    color="#E9DDF8"
                    href="innovative">
                    {t('why_dreamaudit.argument.future_ready.description')}
                </ArgumentFrame>
            </div>
            
        </section>

    )
}