import './SubSection.css'

export const SubSection = ({title, children, animation}) => (
    <article className="sub-section">
        <div className="animation-wrapper">
            {animation}
        </div>
        <h4 className="title">
            {title}
        </h4>
        <p>
            {children}
        </p>
    </article>
)