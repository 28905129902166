/* Scrolle jusqu'à une destination cible */
export const scrollTo = (e) => {
    e.preventDefault();
    const element = e.target.closest("a");
    const target = element.getAttribute("href");
    const targetElement = document.getElementById(target);
    if (targetElement) {
      // targetElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetElement.offsetTop - 128,
        behavior: 'smooth'
      })
      // window.location.hash = target;
    }
}