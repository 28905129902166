import './App.css';
import { Route, Routes } from 'react-router-dom';
import LanguagePath from './i18n/LanguagePath';


const App = () => {
  return (
    <Routes>
      <Route index path=":lang?/*" element={<LanguagePath />} />
    </Routes>
  );
}

export default App;
