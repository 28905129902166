import { useExternalLink } from '../../i18n/useExternalLink';
import './Buttons.css'
import { Button } from './Buttons';

export const ContactButton = ({children, ...rest}) => {

    const { contactEmail } = useExternalLink()
    const contactLink = `mailto:${contactEmail}?subject=Demande de démo DreamAudit`
    return (
        <Button variant="primary" {...rest} target="_blank" href={contactLink}>
            {children}
        </Button>
    )
}