import './Synthesis.css'
import balance from "./assets/balance.svg"
import calendar from "./assets/calendar.svg"
import contact from "./assets/contact.svg"
import diagram from "./assets/diagram.svg"
import flow from "./assets/flow.svg"
import list from "./assets/list.svg"
import matrix from "./assets/matrix.svg"
import message from "./assets/message.svg"
// import pennant from "./assets/pennant.svg"
import pdfFile from "./assets/pdf-file.svg"
import pie from "./assets/pie.svg"
import pin from "./assets/pin.svg"
// import pin2 from "./assets/pin2.svg"
import search from "./assets/search.svg"
import warehouse from "./assets/warehouse.svg"

export const Synthesis = () => {
    const leftItems = [
        {icon: contact},
        {icon: message},
        {icon: balance},
        {icon: pin},
        {icon: calendar},
        {icon: flow},
    ]
    const rightItems = [
        {icon: search},
        {icon: diagram},
        {icon: warehouse},
        {icon: list},
        {icon: matrix},
        {icon: pie},
    ]
    return (
        <div className="synthesis">
            <ItemList items={leftItems} />
            <FileLoader />
            <ItemList items={rightItems} />
        </div>
    )
}

const FileLoader = () => (
    <div className="file-loader">
        <img src={pdfFile} alt="PDF file" />
    </div>
)

const ItemList = ({items}) => (
    <ul className="item-list">
        {items.map((item, i) => (
            <li key={i}>
                <img src={item.icon} alt="specific sheet icon" loading="lazy" />
            </li>
        ))}
        {items.map((item, i) => (
            <li key={items.length + i}>
                <img src={item.icon} alt="specific sheet icon" loading="lazy" />
            </li>
        ))}
        {items.map((item, i) => (
            <li key={items.length*2 + i}>
                <img src={item.icon} alt="specific sheet icon" loading="lazy" />
            </li>
        ))}
    </ul>
)